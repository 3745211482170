import React from 'react'
//import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import BaffleText from 'components/baffle-text'
import './styles.scss'

class Exps extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          show: false,
        }
        this.show = this.show.bind(this)
      }
    
      static contextType = ThemeContext
    
      show() {
        this.setState({ show: true })
      }

    render() {
        return (
            <section id={`${this.props.id}`} className="skills-wrapper exps" style={{height: this.context.height}}>
                {/* {this.particles()} */}
                    <div className="container-fluid skills">   
                        <Row>
                            <Col md={12}>
                            <div class="content">
                            <div className="line-text">
                                <h4>Level</h4>
                                </div>
                                <div className="heading">
                                    <BaffleText
                                    text="Education & Work Experience"
                                    revealDuration={500}
                                    revealDelay={500}
                                    parentMethod={this.show}
                                    callMethodTime={1100}
                                    />
                                </div>   
                                </div> 
                            </Col>
                        </Row>
                        <Row className="detail-row"> 
                        <Col md={6}>
                                <div class="content">
                                    <div className="skills-container">
                                    <h2>Posts & telecommunications institute of technology <span>2010 - 2014</span></h2>
                                    <p><b>Degree: </b> Bachelor</p>
                                    <p><b>Major Field: </b> Information Technology</p>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row className="detail-row"> 
                        <Col md={6}>
                            <div class="content right-content">
                                <div className="skills-container">
                                    <h2>DONG PHUONG Hospital<span>2014 - 2016</span></h2>
                                    <h3>Senior Web Developer and Designer</h3>
                                    <h3>Responsibilities: </h3>
                                    <ul>
                                        <li>Designing logo, banner and layouts for website and application</li>
                                        <li>Developing Website</li>
                                        <li>Consult strategy marketing for CEO and managers</li>
                                    </ul>
                                    <h3>Technologies: </h3>
                                    <ul>
                                        <li>Html5, Css3, Jquery, Sass, Angularjs </li>
                                        <li>Mysql, MongoDB</li>
                                        <li>CodeIgniter, Wordpress</li>
                                        <li>Photoshop, XD</li>
                                        <li>Github, Jira</li>
                                    </ul>
                                    <h3>Projects: </h3>
                                    <ul>
                                        <li>phukhoa497.vn</li>
                                        <li>phukhoadongphuong.com</li>
                                        <li>phongkhamdongphuong.org</li>
                                        <li>ngoaikhoahanoi.com</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div class="content right-content">
                                <div className="skills-container">
                                    <h2>Freelancer <span>2016 - 2019</span></h2>
                                    <h3>Senior Web Developer and Designer</h3>
                                    <h3>Responsibilities: </h3>
                                    <ul>
                                        <li>Designing logo, banner and layouts for website and application</li>
                                        <li>Developing Website</li>
                                        <li>Consult strategy marketing for CEO and managers</li>
                                    </ul>
                                    <h3>Technologies: </h3>
                                    <ul>
                                        <li>Html5, Css3, Jquery, Sass, Dijango, </li>
                                        <li>Reactjs, React Native, Angularjs, Angular 8</li>
                                        <li>Bootstrap, Material Ui, Ant Design, Native Base, Grid 960</li>
                                        <li>Mysql, MongoDB</li>
                                        <li>CodeIgniter, Lavarel, Wordpress, Moodle</li>
                                        <li>Photoshop, XD</li>
                                        <li>Gitlab, Github, Bitbucket, Trello, Jira, Unfuddle</li>
                                    </ul>
                                    <h3>Projects: </h3>
                                    <ul>
                                        <li>maidzo.vn</li>
                                        <li>hifiship.com</li>
                                        <li>learnlock.co.uk</li>
                                        <li>moonlightrestaurant.com.au</li>
                                        <li>vanma.vn</li>
                                        <li>imigroup.vn</li>
                                        <li>vicluxy.com.vn</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="detail-row"> 
                        <Col md={6}>
                            <div class="content right-content">
                                <div className="skills-container">
                                    <h2>Clevver Mail Company<span>2017 - 2019</span></h2>
                                    <h3>Senior Web Developer and Designer</h3>
                                    <h3>Responsibilities: </h3>
                                    <ul>
                                        <li>Convert layout design from PSD, Sketch, Figma files to Html5, Css3 and Javascript</li>
                                        <li>Develop Guideline styling feature for system</li>
                                        <li>Change New Design for systems</li>
                                    </ul>
                                    <h3>Technologies: </h3>
                                    <ul>
                                        <li>Html5, Css3, Jquery, Sass </li>
                                        <li>Reactjs, React Native, Angularjs, Angular 8</li>
                                        <li>Redux, Vuex</li>
                                        <li>Bootstrap, Material Ui, Ant Design, Native Base, Grid 960</li>
                                        <li>Mysql, MongoDB, Rest API</li>
                                        <li>CodeIgniter, Lavarel, Wordpress, Moodle</li>
                                        <li>Photoshop, XD</li>
                                        <li>Gitlab, Github, Bitbucket, Trello, Jira, Unfuddle</li>
                                    </ul>
                                    <h3>Projects: </h3>
                                    <ul>
                                        <li>clevver.io</li>
                                        <li>medaille24.com</li>
                                        <li>eu.clevver.io</li>
                                        <li>dicster.com</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div class="content right-content">
                                <div className="skills-container">
                                    <h2>CMC Global Company Limited <span>2020 - 2021</span></h2>
                                    <h3>Senior Web Developer and Designer</h3>
                                    <h3>Responsibilities: </h3>
                                    <ul>
                                        <li>Develope Website and Mobile Application</li>
                                    </ul>
                                    <h3>Technologies: </h3>
                                    <ul>
                                        <li>Html5, Css3, Jquery, Sass, Dijango, </li>
                                        <li>Reactjs, React Native, Angularjs, Vuejs</li>
                                        <li>Redux, Vuex</li>
                                        <li>Bootstrap, Material Ui, Ant Design, Native Base, Grid 960</li>
                                        <li>Mysql, MongoDB</li>
                                        <li>CodeIgniter, Lavarel, Wordpress, Moodle</li>
                                        <li>Python, Selenium, Appium</li>
                                        <li>Photoshop, XD</li>
                                        <li>Gitlab, Github, Bitbucket, Trello, Jira, Unfuddle</li>
                                    </ul>
                                    <h3>Projects: </h3>
                                    <ul>
                                        <li>inspiringvacations.com</li>
                                        <li>bookipi.com</li>
                                        <li>zalora.com.sg</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}

export default Exps