import React from 'react'
//import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import BaffleText from 'components/baffle-text'
import './styles.scss'

class Skills extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          show: false,
        }
        this.show = this.show.bind(this)
      }
    
      static contextType = ThemeContext
    
      show() {
        this.setState({ show: true })
      }

    render() {
        return (
            <section id={`${this.props.id}`} className="skills-wrapper" style={{height: this.context.height}}>
                {/* {this.particles()} */}
                    <div className="container-fluid skills">   
                        <Row>
                            <Col md={12}>
                            <div class="content">
                            <div className="line-text">
                                <h4>My Skills, Tools & Technologies</h4>
                                </div>
                                <div className="heading">
                                    <BaffleText
                                    text="What I Can Do"
                                    revealDuration={500}
                                    revealDelay={500}
                                    parentMethod={this.show}
                                    callMethodTime={1100}
                                    />
                                </div>   
                                </div>    
                            </Col>
                        </Row>
                        <Row> 
                        <Col md={6}>
                                <div class="content">
                                    <div className="skills-container">
                                        <Progress name="Design Web, app layouts, banners, posters ..." value={90} delay={1100} />
                                        <Progress name="Html5, css3, Sass, less" value={90} delay={1100} />
                                        <Progress name="Reactjs, React Native, Redux" value={90} delay={1100} />
                                        <Progress name="Vue, VueJS" value={70} delay={1100} />
                                        <Progress name="Angular" value={75} delay={1100} />
                                        <Progress name="AngularJS" value={85} delay={1100} />
                                        <Progress name="Jquery" value={90} delay={1100} />
                                        <Progress name="Bootstrap, Material UI, Ant Design, Foundation, Grid 960, Native Base" value={90} delay={1100} />
                                        <Progress name="Ajax, Axios, Fetch" value={90} delay={1100} />
                                    </div>
                                </div>
                        </Col>
                        <Col md={6}>
                        <div class="content">
                                    <div className="skills-container">
                                        <Progress name="Wordpress" value={90} delay={1100} />
                                        <Progress name="Drupal, Joomla, Moodle, Typo3" value={75} delay={1100} />
                                        <Progress name="Php, CodeIgniter" value={80} delay={1100} />
                                        <Progress name="Rest API, Graphql, Apollographql" value={90} delay={1100} />
                                        <Progress name="Mysql, MongoDB" value={75} delay={1100} />
                                        <Progress name="Python" value={80} delay={1100} />
                                        <Progress name="Selenium, Appium" value={80} delay={1100} />
                                        <Progress name="Photoshop, Adobe Illustrator, Abode XD, Figma" value={80} delay={1100} />
                                        <Progress name="Github, Gitlab, BitBucket, Jira, Unfuddle, Trello" value={85} delay={1100} />
                                    </div>
                                    </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }

    // particles() {
    //     return (
    //         <Particles
    //             className="particles"
    //             params={{
    //                 "particles": {
    //                     "number": {
    //                         "value": 50,
    //                         "density": {
    //                             "enable": false,
    //                             "value_area": 5000
    //                         }
    //                     },
    //                     "line_linked": {
    //                         "enable": true,
    //                         "opacity": .5
    //                     },
    //                     "size": {
    //                         "value": 1
    //                     },
    //                 },
    //                 "retina_detect": true
    //         }}/>
    //     )
    // }

}

export default Skills