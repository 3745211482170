import React from 'react'
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Contact from 'components/contact'
import Typewriter from 'typewriter-effect'
import ThemeContext from '../../context'
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin,faFacebook } from '@fortawesome/free-brands-svg-icons'
class Hero extends React.Component {

  static contextType = ThemeContext

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="dusan"
        style={{ height: this.context.height }}
      >
        {this.particles()}
        <div className="container-fluid">
        <Row>
          <Col md={6}>
            <div className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <Glitch text="Dzung Nguyen" />
              <Typewriter
                options={{
                  strings: [
                    'Chapter Leader',
                    'UI/UX Designer',
                    'Frontend Developer',
                    'Mobile App Developer'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <p>Desiring to become a programming expert who work for big companies in both domestic and foreign.</p>
              <p>Looking forward to a long term with a business to promote my abilities, also improve my job skills and life skills.</p>
              <p>Having the opportunity to meet with a professional working environment with the experienced and creative working team.</p>
              <p> Trying to learn more about programming knowledge, about front-end and back-end in both website and mobile application</p>
              <hr />

              <div className="social social_icons">
                  <Contact />
                  <FontAwesomeIcon icon={faFacebook} className="social_icon" onClick={() => window.open('https://www.facebook.com/dusankekic8888')} />
                  <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/dzung-nguyen-3036b510a/')} />
              </div>
              {/* <button className="hover-button">
                  <span>Download CV</span>
              </button> */}
            </div>
            {this.icons()}
            </div>
          </Col>
          <Col md={6} className="img">
            <img
              //src={this.props.mainImg.childImageSharp.fluid.src}
              src="https://cdn.discordapp.com/attachments/751834078801821828/848731554695479326/25591592_531658127197862_7652605522841380075_n.jpeg"
              alt="person"
            />
          </Col>
        </Row>
        </div>
      </section>
    )
  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
          } float-image hidden`}
          style={{
            left: `${index * 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)}%`,
          }}
          alt="shape"
          key={index}
        />
      )
    })
  }
  particles() {
    return (
        <Particles
            className="particles"
            params={{
                "particles": {
                    "number": {
                        "value": 50,
                        "density": {
                            "enable": false,
                            "value_area": 5000
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "opacity": .5
                    },
                    "size": {
                        "value": 5
                    },
                },
                "retina_detect": true
        }}/>
    )
}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "person.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
