import React , { useState, useEffect }from 'react'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
function Contact() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="primary" className="getInTouch" onClick={handleShow}>
            Get In Touch
        </Button>
  
        <Modal show={show} onHide={handleClose} size="lg" centered animation={false} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header>
            <Modal.Title>Get In Touch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Address: P1118 - No 7B Building - Hoang Liet Ward - Hoang Mai district - Ha Noi City</p>
            <p>Mobile: 0915679986 </p>
            <p>Email: dungnguyen19922004@gmail.com</p>
            <p>Skype: dusankekic8888 </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  export default Contact